import { instanceApi } from 'src/utils/axios'
import { Service } from './core/service'

export class NewDealService extends Service {
  protected apiPath = '/api/app/new-deal'

  getDealDetails(dealId: string) {
    return instanceApi.get<any>(`${this.apiPath}/${dealId}/details`)
  }

  deleteChild(childId: string) {
    return instanceApi.delete<any>(`${this.apiPath}/${childId}/lead-child`)
  }
}
